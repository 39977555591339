import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { EventsDataBack, EventsItem } from 'interfaces/Events/Events';

import { SwiperSlide } from 'swiper/react';
import Slider from 'components/blocks/Slider/Slider';

import styles from './Events.module.scss';
import { mainPageAnchors } from 'helpers/anchors';
import EventsGalleryFromBack from './EventsGallery/EventsGalleryFromBack';
import { useGetEvents } from 'helpers/events';

type EventsBackProps = {
  eventsData?: EventsItem[];
};

const EventsBack: React.FC<EventsBackProps> = ({ eventsData }) => {
  const { t } = useTranslation('events');
  const events = useGetEvents(eventsData);

  return (
    <section
      id={mainPageAnchors.events}
      className={cn('content', styles.events)}
    >
      {eventsData?.length && (
        <Slider
          title={t('events.title')}
          spaceBetween={15}
          breakpoints={{
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 12,
            },
          }}
        >
          {eventsData?.length &&
            events?.map((item: EventsDataBack, i) => (
              <SwiperSlide
                className={cn(styles.events__group, styles[item.type])}
                key={i}
              >
                {item?.elems?.map((elem, e) => (
                  <EventsGalleryFromBack key={`${i}${e}`} singleEvent={elem} />
                ))}
              </SwiperSlide>
            ))}
        </Slider>
      )}
    </section>
  );
};

export default EventsBack;

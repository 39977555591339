import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reviewsMain, reviewsMainEn } from 'helpers/reviewsItems';
import { mediaImages } from 'helpers/mediaImages';
import { useSelector } from 'react-redux';
import { productsMain } from 'helpers/products';
import { RootState } from 'stores/store';
import { IUserStore } from 'stores/userStore';
import { useExtractedReviews } from 'helpers/hooks/reviewItems';
import { sortNews } from 'helpers/news';
import Layout from 'components/layouts/Layout/Layout';
import Advantages from 'components/blocks/Advantages';
import Media from 'components/blocks/Media';
import Main from 'components/blocks/HomePage/Main';
import Organizers from 'components/blocks/HomePage/Organizers';
import Faq from 'components/blocks/Faq';
import Tariffs from 'components/blocks/HomePage/Tariffs';
import Product from 'components/blocks/HomePage/Product';
import Head from 'next/dist/next-server/lib/head';
import News from 'components/blocks/News';
import Requirements from 'components/blocks/HomePage/Requirements';
import ReviewsSlider from 'components/blocks/Reviews/ReviewsSlider';
import { PartnersItem } from 'services/partnersService';
import Partners from 'components/blocks/Partners';
import Ambassadors from 'components/blocks/Ambassadors';
import { AmbassadorsItem } from 'services/ambassadorsService';
import Blog from 'components/blocks/Blog';
import { NewsItemBackAdmin } from 'interfaces/News/News';
import { EventsItem } from 'interfaces/Events/Events';
import EventsBack from 'components/blocks/Events/EventsBack';

type HomePageProps = {
  partnersData?: PartnersItem[];
  ambassadorsData?: AmbassadorsItem[];
  newsData?: NewsItemBackAdmin[];
  eventsData?: EventsItem[];
};

const HomePage: React.FC<HomePageProps> = ({
  partnersData,
  ambassadorsData,
  newsData,
  eventsData,
}) => {
  const { t } = useTranslation([
    'media',
    'reviews',
    'homepage',
    'products',
    'common',
    'howItWorks',
    'events',
  ]);
  const userStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = userStored.geoData;

  const getProducts = geoDataStored => {
    const productsId = geoDataStored === 'RU' ? [5, 16, 8, 13] : [4, 2, 3, 5];

    const productsCurrent = productsId.map(id => {
      const productData = productsMain.filter(item => item.id === id)?.[0];

      if (!productData) {
        return;
      }

      return {
        ...productData,
        name: t(`products.items.${id}.nameShort`, { ns: 'products' }),
      };
    });

    return productsCurrent;
  };

  const [products, setProducts] = useState(getProducts(geoDataStored));

  const media = sortNews(
    mediaImages.map(item => {
      return {
        ...item,
        name: t(`media.items.${item.id}.title`, { ns: 'media' }),
        text: t(`media.items.${item.id}.text`, { ns: 'media' }),
      };
    })
  );

  const tariffs = [
    {
      id: 2,
    },
    {
      id: 4,
    },
  ];

  useEffect(() => {
    setProducts(getProducts(geoDataStored));
  }, [geoDataStored]);

  return (
    <>
      <Head>
        <meta
          content={t('common.meta.title', { ns: 'common' })}
          property='og:title'
        />
        <meta
          content={t('common.meta.description', { ns: 'common' })}
          property='og:description'
        />
        <meta
          name='description'
          content={t('common.meta.description', { ns: 'common' })}
        />
        <title>{t('common.meta.title', { ns: 'common' })}</title>
      </Head>
      <Layout>
        <Main />
        <Partners partnersData={partnersData} />
        <Advantages />
        <Tariffs data={tariffs} />
        <Requirements />
        <Organizers />
        {(geoDataStored === 'RU' || geoDataStored === 'US') && (
          <Product data={products} />
        )}
        <EventsBack eventsData={eventsData} />
        <ReviewsSlider data={useExtractedReviews(reviewsMain, reviewsMainEn)} />
        <News newsData={newsData} />
        <Ambassadors ambassadorsData={ambassadorsData} />
        <Media data={media} />
        <Blog />
        <Faq />
      </Layout>
    </>
  );
};

export default HomePage;

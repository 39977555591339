export const eventsByGrid = events => {
  switch (events.length) {
    case 1:
      return [
        {
          type: 'single',
          elems: [events[0]],
        },
      ];

    case 2:
      return [
        {
          type: 'single',
          elems: [events[0]],
        },
        {
          type: 'single',
          elems: [events[1]],
        },
      ];

    case 3:
      return [
        {
          type: 'double',
          elems: [events[0], events[1]],
        },
        {
          type: 'single',
          elems: [events[2]],
        },
      ];

    case 4:
      return [
        {
          type: 'double',
          elems: [events[0], events[1]],
        },
        {
          type: 'single',
          elems: [events[2]],
        },
        {
          type: 'single',
          elems: [events[3]],
        },
      ];

    case 5:
      return [
        {
          type: 'double',
          elems: [events[0], events[1]],
        },
        {
          type: 'single',
          elems: [events[2]],
        },
        {
          type: 'single',
          elems: [events[3]],
        },
        {
          type: 'single',
          elems: [events[4]],
        },
      ];

    case 6:
      return [
        {
          type: 'double',
          elems: [events[0], events[1]],
        },
        {
          type: 'single',
          elems: [events[2]],
        },
        {
          type: 'double',
          elems: [events[3], events[4]],
        },
        {
          type: 'single',
          elems: [events[5]],
        },
      ];

    case 7:
      return [
        {
          type: 'double',
          elems: [events[0], events[1]],
        },
        {
          type: 'single',
          elems: [events[2]],
        },
        {
          type: 'double',
          elems: [events[3], events[4]],
        },
        {
          type: 'double',
          elems: [events[5], events[6]],
        },
      ];

    case 8:
      return [
        {
          type: 'double',
          elems: [events[0], events[1]],
        },
        {
          type: 'single',
          elems: [events[2]],
        },
        {
          type: 'double',
          elems: [events[3], events[4]],
        },
        {
          type: 'double',
          elems: [events[5], events[6]],
        },
        {
          type: 'single',
          elems: [events[7]],
        },
      ];

    case 9:
      return [
        {
          type: 'double',
          elems: [events[0], events[1]],
        },
        {
          type: 'single',
          elems: [events[2]],
        },
        {
          type: 'double',
          elems: [events[3], events[4]],
        },
        {
          type: 'double',
          elems: [events[5], events[6]],
        },
        {
          type: 'double',
          elems: [events[7], events[8]],
        },
      ];

    case 10:
      return [
        {
          type: 'double',
          elems: [events[0], events[1]],
        },
        {
          type: 'single',
          elems: [events[2]],
        },
        {
          type: 'double',
          elems: [events[3], events[4]],
        },
        {
          type: 'square',
          elems: [events[5], events[6], events[7], events[8]],
        },
        {
          type: 'single',
          elems: [events[9]],
        },
      ];
  }
};

export const chunkEvents = events => {
  if (!events?.length) {
    return [];
  }

  const chunkSize = 10;
  const chunksGroup = [];

  for (let i = 0; i < events.length; i += chunkSize) {
    chunksGroup.push(events.slice(i, i + chunkSize));
  }

  return chunksGroup;
};

export const formatEventDate = event => {
  const eventWithNewDateFormat = Object.assign({}, event);

  const newDate = [
    Number(event.date.slice(8, 10)),
    Number(event.date.slice(5, 7)),
    Number(event.date.slice(0, 4)),
  ];

  eventWithNewDateFormat.date = newDate;

  return eventWithNewDateFormat;
};

export const sortEvents = events => {
  if (!events?.length) {
    return [];
  }

  return events.sort((prev, next) => {
    if (
      !prev?.date ||
      !next?.date ||
      !(prev.date?.length >= 3 && next.date?.length >= 3)
    ) {
      return 0;
    }

    const prevDate = new Date(prev.date[2], prev.date[1], prev.date[0]);
    const nextDate = new Date(next.date[2], next.date[1], next.date[0]);

    if (nextDate > prevDate) {
      return 1;
    }

    if (nextDate < prevDate) {
      return -1;
    }

    return 0;
  });
};

export const useGetEvents = eventsData => {
  const events = eventsData.map(item => formatEventDate(item));
  const filtredEvents = events.filter(item => item?.visible);
  const sortedEvents = sortEvents(filtredEvents);
  const eventsGroups = chunkEvents(sortedEvents);
  const allEventsByGroups = eventsGroups.map(item => eventsByGrid(item)).flat();

  return allEventsByGroups;
};

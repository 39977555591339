import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { NewsItemBackAdmin } from 'interfaces/News/News';
import {
  useFormatNewsDate,
  useGetCurrentNews,
  useGetNewsFromBack,
  useSortNewsByDate,
  useUnifyNews,
} from 'helpers/news';

import Link from 'next/link';
import { SwiperSlide } from 'swiper/react';
import Slider from 'components/blocks/Slider/Slider';

import styles from './News.module.scss';
import { mainPageAnchors } from 'helpers/anchors';
import { links } from 'helpers/constants/links';

interface NewsProps {
  showButton?: boolean;
  newsData?: NewsItemBackAdmin[];
}

const News: FC<NewsProps> = ({ newsData, showButton = true }) => {
  const { t } = useTranslation(['news', 'common']);

  const { news } = useGetCurrentNews();
  const unifiedNewsFront = useUnifyNews(news);

  const newsBack = useGetNewsFromBack(newsData);
  const formatedDateNewsBack = useFormatNewsDate(newsBack);
  const unifiedNewsBack = useUnifyNews(formatedDateNewsBack);

  const newsForRender = [...unifiedNewsFront, ...unifiedNewsBack];
  const sortedNews = useSortNewsByDate(newsForRender);

  return (
    <section id={mainPageAnchors.news} className={cn(styles.news, 'content')}>
      <Slider
        title={t('news.title')}
        spaceBetween={20}
        breakpoints={{
          480: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            allowTouchMove: newsForRender.length > 2,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            allowTouchMove: newsForRender.length > 3,
          },
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            allowTouchMove: newsForRender.length > 4,
          },
        }}
        className={styles.news__slider}
        showMoreLink={showButton ? `/${links.news}` : ''}
      >
        {sortedNews?.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <Link href={`/${links.news}/${item?.link}`}>
                <a className={cn(styles.news__item, 'link')}>
                  <div className={styles.news__image}>
                    <img
                      src='/images/news/slider-placeholder.jpg'
                      data-src={
                        item?.image.includes('http')
                          ? item?.image.replace(/'/g, '')
                          : `/images/news/${item?.image}`
                      }
                      alt={item?.title}
                      className='img lazyload'
                    />
                  </div>
                  <div className={styles.news__info}>
                    <div
                      className={cn(styles.news__name, styles.news__nameClamp)}
                    >
                      {item?.title}
                    </div>
                    <div className={styles.news__date}>
                      {`${t(`commonMonths.${item?.date[1] - 1}`, {
                        ns: 'common',
                      })} ${item?.date[0]}, ${item?.date[2]}`}
                    </div>
                  </div>
                </a>
              </Link>
            </SwiperSlide>
          );
        })}
      </Slider>
    </section>
  );
};

export default News;

import React, { useState } from 'react';
import cn from 'classnames';

import Lightbox from 'yet-another-react-lightbox/dist';
import Captions from 'yet-another-react-lightbox/dist/plugins/captions';
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';
import { Trans } from 'next-i18next';

import styles from 'components/blocks/Events/Events.module.scss';
import 'yet-another-react-lightbox/dist/styles.css';
import 'yet-another-react-lightbox/dist/plugins/captions/captions.css';
import 'yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css';

interface EventsGalleryFromBackProps {
  singleEvent: any;
}

const EventsGalleryFromBack: React.FC<EventsGalleryFromBackProps> = ({
  singleEvent,
}) => {
  const [open, setOpen] = useState(false);

  const imagesLength = singleEvent?.other_photo_urls?.length;

  const plugins: any[] =
    imagesLength === 1 ? [Captions, Zoom] : [Captions, Thumbnails, Zoom];

  return (
    <div
      className={cn(styles.events__item, {
        [styles.events__itemNoGallery]: !singleEvent?.other_photo_urls,
      })}
      onClick={() => setOpen(true)}
    >
      <img
        src={singleEvent?.main_page_photo_url}
        data-src={singleEvent?.main_page_photo_url}
        alt={singleEvent?.name}
        className={cn('img', 'lazyload', styles.events__image)}
      />
      <div className={styles.events__text}>
        <Trans>{singleEvent?.name}</Trans>
      </div>
      {singleEvent?.other_photo_urls?.length && (
        <Lightbox
          className={styles.lightbox}
          open={open}
          close={() => setOpen(false)}
          plugins={plugins}
          carousel={{
            finite: imagesLength < 3,
          }}
          slides={singleEvent?.other_photo_urls?.map(item => {
            return {
              src: item,
              description: singleEvent?.name?.replace('<br>', '') || '',
            };
          })}
        />
      )}
    </div>
  );
};

export default EventsGalleryFromBack;
